import Line from '@/components/atoms/Line'
import { withLayout } from '@/components/template/Layout'
import { Box, Grid } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import style from '@/utils/style'
import { AppDispatch } from '@/store'
import { useDispatch, useSelector } from 'react-redux'
import { emissionStore, getEmissionFactorTables } from '@/store/slices/emissionSlice'
import CustomPagination from '@/components/molecules/Pagination'

function EmissionFactorList() {
    const [page, setPage] = useState<number>(0)
    const [limit, setLimit] = useState<number>(10)
    const columns: GridColDef[] = [
        { field: 'id', headerName: '種別ID', minWidth: 360, disableColumnMenu: true, flex: 2 },
        { field: 'name', headerName: '種別名称', minWidth: 830, disableColumnMenu: true, flex: 4 },
    ]

    const dispatch = useDispatch<AppDispatch>()
    const { listEmissionFactorTable, total } = useSelector(emissionStore)
    useEffect(() => {
        const fetchData = async () => {
            const params = {
                search: '',
                offset: page * limit,
                limit: limit,
            }
            dispatch(getEmissionFactorTables(params))
        }
        fetchData().catch((err) => {})
    }, [page, limit])

    return (
        <div className="companies-management-wrapper px-40">
            <h3 className="main-header">Emission factor table </h3>
            <Line />
            <Grid item xs={12}>
                <Box>
                    <DataGrid
                        rows={listEmissionFactorTable || []}
                        hideFooter={true}
                        hideFooterPagination={true}
                        columns={columns}
                        sx={style.myGrid}
                        autoHeight
                    />
                    <CustomPagination
                        page={page}
                        setPage={setPage}
                        rowsPerPage={limit}
                        setRowsPerPage={setLimit}
                        total={total}
                    />
                </Box>
            </Grid>
        </div>
    )
}

export default withLayout(EmissionFactorList)
